<template>
  <div />
</template>

<script>
import Cookies from "js-cookie";
import firebase from "firebase";
import { GENERAL_PASSWORD, LINE_DOMAIN } from "@/constants/message";

export default {
  name: "SociaLine",
  metaInfo() {
    return {
      title: "登録メールを確認する",
      titleTemplate: "%s | PreGo"
    };
  },
  async created() {
    this.$root.$refs.loading.start();
    if (this.$route.query.code === undefined) {
      if (localStorage.getItem("sex")) {
        if (Cookies.get("invitation_code")) {
          this.$router.push({
            name: "Register",
            query: { code: Cookies.get("invitation_code") }
          });
        } else {
          this.$router.push({ name: "Register" });
        }
      } else {
        this.$router.push({ name: "LoginEmail" });
      }
      this.$root.$refs.loading.finish();
    } else {
      var params = {
        code: this.$route.query.code,
        sex: localStorage.getItem("sex") || null,
        type: localStorage.getItem("type") || 1
      };

      if (Cookies.get("invitation_code")) {
        params.invitation_code = Cookies.get("invitation_code");
      }

      await this.$store
        .dispatch("auth/callbackLine", params)
        .then(async () => {
          this.$root.$refs.loading.finish();
          if (localStorage.getItem("type") == 2) {
            localStorage.setItem("step", 1);
            localStorage.setItem("add-friend-line", 1);
          }
          if (Cookies.get("invitation_code")) {
            Cookies.remove("invitation_code");
          }
          await this.$store.dispatch("auth/getInfoUser");
          const userGender = this.$store.getters["auth/user"];
          const md5 = require("md5");
          const data = {
            email: userGender.line_id + LINE_DOMAIN,
            password: md5(GENERAL_PASSWORD)
          };
          firebase.auth().signInWithEmailAndPassword(data.email, data.password);
          if (userGender.registration_status == 1) {
            localStorage.removeItem("step");
            localStorage.setItem("step", 1);
            this.$router.push({ name: "RegisterProfile" });
          }
          if (userGender.sex == 1) {
            localStorage.removeItem("add-friend-line");
            localStorage.removeItem("step");
            this.$router.push({
              name: "Home",
              params: {
                showPopup: "show"
              }
            });
          } else {
            localStorage.removeItem("step");
            localStorage.removeItem("add-friend-line");
            this.$router.push({
              name: "CastHome",
              params: {
                showPopup: "show"
              }
            });
          }
        })
        .catch(error => {
          if (error.response.data.error.code === "4043") {
            if (Cookies.get("invitation_code")) {
              this.$router.push({
                name: "TopRegister",
                query: { code: Cookies.get("invitation_code") }
              });
            } else {
              this.$router.push({ name: "TopRegister" });
            }
            this.$toast("LINEアカウントが登録されていません", "通知", "danger");
          }
          if (error.response.data.error.code === "5026") {
            if (Cookies.get("invitation_code")) {
              this.$router.push({
                name: "TopRegister",
                query: { code: Cookies.get("invitation_code") }
              });
            } else {
              this.$router.push({ name: "TopRegister" });
            }
            this.$toast("LINEアカウントが登録されました", "通知", "danger");
          }
          this.$root.$refs.loading.finish();
        });
    }
  }
};
</script>
